import { useAuth0 } from '@auth0/auth0-react';
import { FC, ReactNode, useMemo } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { GRAPH_API } from '../constants';

const ApolloAuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: GRAPH_API,
    });

    // TODO: handle errors
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently({ detailedResponse: true });

      return {
        headers: {
          ...headers,
          authorization: token.id_token ? `Bearer ${token.id_token}` : '',
        },
      };
    });

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache({}),
    });
  }, [getAccessTokenSilently]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAuthProvider;
