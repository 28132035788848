export const GRAPH_API = process.env.REACT_APP_GRAPHQL_API_URI;
export const AUTH0_CONFIG: { domain: string; clientId: string } = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
};

export const VALIDATIONS = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
      message: 'Please enter the email in the correct format.',
    },
  },
  first_name: {
    required: 'First name is required',
    pattern: {
      value: /^[A-Za-z ]+$/i,
      message: 'Please enter valid name.',
    },
  },
  last_name: {
    required: 'Last name is required',
    pattern: {
      value: /^[A-Za-z0-9 ]+$/i,
      message: 'Please enter valid name.',
    },
  },
};
