import React, { FC } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { Program } from '../../types';

const ProgramCard: FC<{ program: Program }> = ({ program }) => {
  const navigate = useNavigate();

  const viewProgram = () => {
    navigate(`program/${program.label.toUpperCase()}`);
  };

  return (
    <Card className="w-full bg-white/10 p-5">
      <CardHeader className="flex gap-3">
        <Image
          alt=""
          data-testid="program module logo"
          radius="sm"
          src="/icons/coin.svg"
          classNames={{
            wrapper: 'flex-shrink-0 bg-secondary/10 p-1 rounded-lg',
          }}
          width={48}
          height={48}
        />
        <div className="flex flex-col">
          <div className="flex flex-wrap">
            <span className="text-lg font-dmsans-medium text-foreground">
              {program.label} Program
            </span>
            <span className="px-1">-</span>
            <span
              data-testid="program-title"
              className="text-lg text-foreground"
            >
              {program.title}
            </span>
          </div>
          <div className="flex items-center gap-4 flex-wrap">
            <p
              className="text-md text-subtitle capitalize pt-1"
              data-testid="module-count"
            >
              {program.total_modules} Modules
            </p>
            <p
              className="text-md text-subtitle capitalize pt-1"
              data-testid="lesson-count"
            >
              {program.total_lessons} Lessons
            </p>
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-3 pt-2 h-fit overflow-y-visible">
        <p>{program.description}</p>
        <p className="pt-2">
          Please click View Program to add your student(s) and see more details.
        </p>
      </CardBody>
      <CardFooter className="pt-10">
        <Button
          data-testid="view-program-btn"
          color="secondary"
          variant="solid"
          onPress={viewProgram}
          className="font-dmsans-medium text-lg w-full active:bg-focus hover:bg-focus focus:outline-0"
          size="lg"
        >
          View Program
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ProgramCard;
