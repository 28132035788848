export enum OnboardStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
}

export enum Role {
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  PARENT = 'PARENT',
  KID = 'KID',
}

export enum ModuleStatus {
  IN_PROGRESS = 'InProgress',
  NOT_STARTED = 'NotStarted',
  COMPLETED = 'Completed',
}

export const getModuleStatus = (s: ModuleStatus) => {
  switch (s) {
    case ModuleStatus.IN_PROGRESS:
      return 'In Progress';
    case ModuleStatus.NOT_STARTED:
      return 'Not Started';
    case ModuleStatus.COMPLETED:
      return 'Completed';
    default:
      return '';
  }
};

export type UserProfile = {
  first_name: string;
  last_name: string;
  email: string;
  lms_user_id: string;
  client?: {
    name: string;
    id: number;
  };
  onboard_status: OnboardStatus;
  role: Role;
};

export type Lesson = {
  id: string;
  title: string;
  completed: boolean;
  url: string;
  activity_url: string;
  plan_url: string;
  index: number;
};

export type KgmModule = {
  name: string;
  curriculum_url: string;
  logo_url: string;
  status: ModuleStatus;
  progress: string;
  lessons: Lesson[];
};

export type ProgramDetails = {
  name: string; // program name. Money skills for life, etc
  curriculum_url: string; // curriculum download
  url: string; // view student lesson button (program url)
  progress: string; // student / kid progress on program
  modules: KgmModule[];
};

export type KgmProgramQuery = {
  programmodule: ProgramDetails;
};

export type ProfileQuery = {
  user: UserProfile;
};

export type Inviter = {
  name: string;
  email: string;
};

export type LessonProgress = {
  lesson_no: number;
  status: string;
  score?: string | null;
};

export type ModuleProgress = {
  module: number;
  lesson_progress: LessonProgress[];
};

export type Learner = {
  name: string;
  email: string;
  id: number;
  progress: ModuleProgress[];
  inviter: Inviter;
};

export type GetLearnerProgressResponse = {
  learners: {
    nextPageNum: number;
    total: number;
    learners: Learner[];
  };
};

export type Program = {
  title: string;
  label: string;
  total_modules: number;
  total_lessons: number;
  program_type: string;
  description: string;
};

export type KgmProgramsQuery = {
  programs: Program[];
};
