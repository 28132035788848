import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Header from './components/common/header/Header';
import { ToastContainer } from 'react-toastify';

function Layout() {
  return (
    <>
      <ScrollRestoration />
      <Header />
      <div aria-live="polite">
        <ToastContainer
          position="top-center"
          autoClose={false}
          closeOnClick
          theme="light"
        />
      </div>
      <div className="max-w-[1750px] mx-auto">
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
