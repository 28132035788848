import React, { useCallback, useEffect, useRef, useState } from 'react';
import EmptyTable from './EmptyTable';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  Button,
} from '@nextui-org/react';
import { useQuery } from '@apollo/client';
import { GetLearnerProgressResponse, Learner } from '../../types';
import { ReactComponent as RecycleIcon } from '../../components/common/icons/Recycle.svg';
import { GET_LEARNER_PROGRESS } from '../../graphql/learners';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useParams } from 'react-router-dom';
import { StudentListTableFilter } from './constants';

export type StudentsListingTableProps = {
  activeFilter: StudentListTableFilter;
  renderCell: (user: Learner, columnKey: React.Key) => JSX.Element;
  programLabel: string;
  columns: { name: string; uid: string }[];
};

const StudentsListingTable = (props: StudentsListingTableProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState<boolean | undefined>(undefined);
  const [learners, setLearners] = useState<Learner[]>([]);
  const { programId } = useParams();
  const programLabel = (programId as string).toUpperCase();
  const nextPageNum = useRef(1);
  const [totalResults, setTotalResults] = useState(0);

  const TableTopContent = () => {
    return (
      <div
        className="flex flex-column items-center justify-between"
        data-testid="refresh-table"
      >
        <div>
          <p className="font-bold" data-testid="total-results">
            Total {totalResults} results
          </p>
        </div>
        <div>
          <Button
            color="secondary"
            variant="light"
            startContent={
              <div
                className="bg-secondary flex items-center justify-center rounded-full cursor-pointer"
                data-testid="refresh-btn-icon"
              >
                <RecycleIcon
                  width={24}
                  height={24}
                  className="fill-secondary"
                />
              </div>
            }
            onPress={refreshTable}
            className="font-dmsans-medium text-lg mr-2 w-full focus:outline-0"
            size="lg"
            isDisabled={isLoading || loading}
          >
            Refresh Table
          </Button>
        </div>
      </div>
    );
  };

  const { fetchMore, loading, error } = useQuery<GetLearnerProgressResponse>(
    GET_LEARNER_PROGRESS,
    {
      fetchPolicy: 'network-only',
      variables: {
        pageNum: 1,
        limit: 10,
        program: programLabel,
        invited_by_me:
          props.activeFilter === StudentListTableFilter.MY_STUDENTS,
      },
    },
  );

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const fetchMoreLearners = useCallback(
    async (filter: StudentListTableFilter, pageNum = 1) => {
      if (hasMore === false || pageNum <= 0) {
        setIsLoading(false);
        return;
      }
      await fetchMore({
        variables: {
          pageNum,
          limit: 10,
          program: programLabel,
          invited_by_me: filter === StudentListTableFilter.MY_STUDENTS,
        },
        updateQuery: (prevResults, { fetchMoreResult }) => {
          let uniqueResults: Learner[] = [];
          if (pageNum <= 1) {
            uniqueResults = [...fetchMoreResult.learners.learners];
          } else {
            uniqueResults = [...prevResults.learners.learners];
            fetchMoreResult.learners.learners.forEach(learner => {
              const found = uniqueResults.find(l => l.id === learner.id);
              if (!found) {
                uniqueResults.push(learner);
              }
            });
          }
          setLearners(uniqueResults);
          setHasMore(fetchMoreResult.learners.nextPageNum > 1);
          setTotalResults(fetchMoreResult.learners.total);
          setIsLoading(false);
          nextPageNum.current = fetchMoreResult.learners.nextPageNum;
          return {
            learners: {
              ...fetchMoreResult.learners,
              learners: uniqueResults,
            },
          };
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: () => {
      if (!hasMore) return;
      fetchMoreLearners(props.activeFilter, nextPageNum.current);
    },
  });

  useEffect(() => {
    fetchMoreLearners(props.activeFilter);
  }, [fetchMoreLearners, props.activeFilter]);

  const refreshTable = useCallback(() => {
    setLearners([]);
    setIsLoading(true);
    scrollerRef?.current?.scrollTo &&
      scrollerRef?.current?.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    fetchMoreLearners(props.activeFilter, 1);
  }, [props.activeFilter, fetchMoreLearners, scrollerRef]);

  return (
    <Table
      isStriped
      isHeaderSticky
      baseRef={scrollerRef}
      bottomContent={
        hasMore ? (
          <div className="flex w-full justify-center">
            <Spinner ref={loaderRef} color="primary" />
          </div>
        ) : null
      }
      data-testid={`students-list-table-${props.activeFilter}`}
      topContent={<TableTopContent />}
      topContentPlacement="outside"
      classNames={{
        base: 'max-h-[900px]',
        table: 'max-h-[820px] py-10 bg-white',
        td: 'align-top',
        thead: ['-top-4'],
      }}
      aria-label="Students list with progress details"
    >
      <TableHeader columns={props.columns} className="top-4">
        {column => (
          <TableColumn
            key={column.uid}
            align={'center'}
            isRowHeader
            textValue={column.name}
            scope="col"
            tabIndex={0}
            className="font-bold leading-6 text-base capitalize text-[#2D3543] focus:outline-0"
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={
          <EmptyTable programLabel={programLabel} error={error?.message} />
        }
        isLoading={isLoading}
        loadingContent={<Spinner color="primary" />}
      >
        {learners.map(learner => (
          <TableRow className="h-20" key={learner.id}>
            {columnKey => (
              <TableCell className="pt-2 pr-0 pb-2 pl-2.5">
                {props.renderCell(learner, columnKey)}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default StudentsListingTable;
