import React, { useCallback, useState } from 'react';
import { ButtonGroup, Button, Tooltip } from '@nextui-org/react';
import { ReactComponent as InfoIcon } from '../common/icons/InfoCircle.svg';
import { Learner } from '../../types';
import { LessonProgress } from './LessonProgress';
import StudentsListingTable from './StudentsListingTable';
import {
  StudentListTableFilter,
  TableConstants,
  allStudentsTableColumns,
  myStudentsTableColumns,
} from './constants';
import { useParams } from 'react-router-dom';
import { School } from '../../providers/ProfileProvider';

const StudentsListing = () => {
  const [activeFilter, setActiveFilter] = useState(
    StudentListTableFilter.MY_STUDENTS,
  );
  const { programId } = useParams();
  const programLabel = programId as string;

  const renderCell = useCallback((user: Learner, columnKey: React.Key) => {
    switch (columnKey) {
      case TableConstants.STUDENT.uid:
        return (
          <div className="flex flex-col">
            <p className="font-bold text-md capitalize leading-5">
              {user.name}
            </p>
            <Tooltip content={user.email}>
              <p
                className="font-bold text-sm text-default-400 truncate w-52"
                aria-hidden="true"
              >
                {user.email}
              </p>
            </Tooltip>
            <span className="sr-only">{user.email}</span>
          </div>
        );
      case TableConstants.TEACHER.uid:
        return (
          <div className="flex flex-col">
            <p className="font-bold text-md capitalize leading-5 truncate w-52">
              {user.inviter.name}
            </p>
            <Tooltip content={user.inviter.email}>
              <p
                className="font-bold text-sm text-default-400 truncate w-52"
                aria-hidden="true"
              >
                {user.inviter.email}
              </p>
            </Tooltip>
            <span className="sr-only">{user.inviter.email}</span>
          </div>
        );
      case TableConstants.MODULE1.uid:
        return <LessonProgress data={user.progress[0]} />;
      case TableConstants.MODULE2.uid:
        return <LessonProgress data={user.progress[1]} />;
      case TableConstants.MODULE3.uid:
        return <LessonProgress data={user.progress[2]} />;
      default:
        return <></>;
    }
  }, []);

  const TableFilters = () => {
    const isAllStudentsFilterSelected =
      activeFilter === StudentListTableFilter.ALL_STUDENTS;
    return (
      <div data-testid="table-filters">
        <ButtonGroup className="gap-3">
          <Button
            className={`py-6 rounded-lg w-36 font-medium text-lg leading-12 focus:outline-0 ${
              !isAllStudentsFilterSelected ? 'text-white' : 'text-black'
            }`}
            color={!isAllStudentsFilterSelected ? 'primary' : 'default'}
            variant={!isAllStudentsFilterSelected ? 'solid' : 'light'}
            onPress={() => {
              setActiveFilter(StudentListTableFilter.MY_STUDENTS);
            }}
            role="button"
          >
            My Students
          </Button>
          <School>
            <Button
              className={`py-6 rounded-lg w-36 font-medium text-lg leading-12 focus:outline-0 ${
                isAllStudentsFilterSelected ? 'text-white' : 'text-black'
              }`}
              color={isAllStudentsFilterSelected ? 'primary' : 'default'}
              variant={isAllStudentsFilterSelected ? 'solid' : 'light'}
              onPress={() => {
                setActiveFilter(StudentListTableFilter.ALL_STUDENTS);
              }}
            >
              All Students
            </Button>
          </School>
        </ButtonGroup>
        <div className="flex justify-between">
          <div>
            <p className="text-foreground py-3 flex">
              View and track the progress of{' '}
              {isAllStudentsFilterSelected ? 'all' : 'your'} students invited to
              the {programLabel} program.
              <Tooltip
                placement="right"
                content={
                  <div className="px-1 py-2 w-80 align-items-center">
                    <div className="text-medium font-bold">Need Help?</div>
                    <div className="text-small">
                      <p>
                        If you aren't seeing the students you expected, please
                        contact the support team for assistance.
                      </p>
                      <a
                        className="text-[#3359DE] underline"
                        href="mailto: support@kidsget.money"
                      >
                        support@kidsget.money
                      </a>
                    </div>
                  </div>
                }
              >
                <InfoIcon
                  className="flex-inline w-7 p-1 cursor-pointer"
                  role="img"
                  aria-label="Help information"
                  tabIndex={0}
                />
              </Tooltip>
              <span className="sr-only">
                If you aren't seeing the students you expected, please contact
                the support team for assistance. support@kidsget.money
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (activeFilter === StudentListTableFilter.ALL_STUDENTS) {
    return (
      <>
        <TableFilters />
        <StudentsListingTable
          programLabel={programLabel}
          activeFilter={activeFilter}
          renderCell={renderCell}
          columns={allStudentsTableColumns}
        />
      </>
    );
  } else {
    return (
      <>
        <TableFilters />
        <StudentsListingTable
          programLabel={programLabel}
          activeFilter={activeFilter}
          renderCell={renderCell}
          columns={myStudentsTableColumns}
        />
      </>
    );
  }
};
export default StudentsListing;
