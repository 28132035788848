import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Image, Chip } from '@nextui-org/react';
import { KgmModule, Lesson, ModuleStatus, getModuleStatus } from '../../types';
import { Learner, useProfile } from '../../providers/ProfileProvider';
import { useTheme } from 'next-themes';
import LessonActionItems from '../lesson-action-items/LessonActionItems';
import LessonPrefix from '../lesson-prefix/LessonPrefix';
import { openUrlInNewTab } from '../../utils';

const LessonItem: FC<{
  lesson: Lesson;
  classList: string;
}> = ({ lesson, classList }) => {
  const { isAuthor } = useProfile();
  const ref = useRef<HTMLLIElement>(null);

  const onLessonClick = useCallback(() => {
    if (isAuthor) {
      openUrlInNewTab(lesson.plan_url);
    }
  }, [isAuthor, lesson.plan_url]);

  useEffect(() => {
    if (ref && ref.current) {
      const liRef = ref.current;
      liRef.addEventListener('click', onLessonClick, false);
      return function cleanup() {
        liRef.removeEventListener('click', onLessonClick, false);
      };
    }
  }, [onLessonClick]);

  return (
    <li className={classList} ref={ref}>
      <div className="flex items-center">
        <LessonPrefix title={lesson.index + ''} />
        <p className="text-md leading-6 break-all">{lesson.title}</p>
      </div>
      <LessonActionItems lesson={lesson} />
    </li>
  );
};

const ProgramModule: FC<{ summary: KgmModule }> = ({ summary }) => {
  const { theme } = useTheme();
  const role = theme as 'author' | 'learner';

  const lessonClass: Record<'author' | 'learner', string> = {
    author:
      'flex justify-between items-center h-16 py-6 sm:py-1 px-3 rounded-none gap-3 data-[hover=true]:text-secondary data-[hover=true]:bg-transparent border-none text-foreground hover:font-dmsans-medium cursor-pointer group/author-item',
    learner:
      'flex justify-between items-center h-16 py-6 sm:py-1 px-3 rounded-none gap-3 data-[hover=true]:bg-transparent border-none text-foreground cursor-auto group/learner-item',
  };

  let progressClass: string = '';
  switch (summary.status) {
    case ModuleStatus.IN_PROGRESS:
      progressClass = 'bg-success/30';
      break;
    case ModuleStatus.COMPLETED:
      progressClass = 'bg-success';
      break;
    default:
      progressClass = '';
      break;
  }

  return (
    <Card className="w-full bg-secondary/10 p-5">
      <CardHeader className="flex gap-3">
        <Image
          alt=""
          data-testid="program module logo"
          radius="sm"
          src={summary.logo_url}
          classNames={{
            wrapper: 'flex items-center justify-center w-12 h-12 bg-white p-1',
          }}
          width={100}
          height={100}
        />
        <div className="flex flex-col">
          <p
            className="text-xl font-dmsans-medium text-foreground"
            id={summary.name.replace(' ', '-').toLowerCase()}
          >
            {summary.name}
          </p>
          <div className="flex items-center gap-4">
            <p
              className="text-md text-foreground capitalize pt-1"
              data-testid="lesson-count"
            >
              {summary.lessons.length} lessons
            </p>
            <Learner>
              <div
                className="text-md text-subtitle capitalize pt-1"
                data-testid="lesson-status"
              >
                <Chip
                  size="sm"
                  classNames={{
                    base: progressClass,
                  }}
                >
                  {getModuleStatus(summary.status)}
                </Chip>
              </div>
            </Learner>
          </div>
        </div>
      </CardHeader>
      <CardBody className="p-0 pt-2 h-fit overflow-y-visible">
        <Learner>
          <div className="flex items-center justify-end relative w-full gap-7 h-8 text-foreground text-sm leading-[30px] pr-3">
            <div>Lesson</div>
            <div>Activity</div>
          </div>
        </Learner>
        <ul>
          {summary.lessons.map(lesson => {
            return (
              <LessonItem
                key={lesson.id}
                lesson={lesson}
                classList={lessonClass[role]}
              />
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
};

export default ProgramModule;
