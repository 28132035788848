import React, { FC, SVGProps } from 'react';

const School: FC<SVGProps<SVGSVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 8.25H17V3.25C17 2.5625 16.4375 2 15.75 2H3.25C2.5625 2 2 2.5625 2 3.25V20.75C2 21.4375 2.5625 22 3.25 22H7V17H12V22H20.75C21.4375 22 22 21.4375 22 20.75V9.5C22 8.8125 21.4375 8.25 20.75 8.25ZM8.25 14.5H4.5V10.75H8.25V14.5ZM8.25 8.25H4.5V4.5H8.25V8.25ZM14.5 14.5H10.75V10.75H14.5V14.5ZM14.5 8.25H10.75V4.5H14.5V8.25ZM19.5 19.5H17V17H19.5V19.5ZM19.5 14.5H17V10.75H19.5V14.5Z"
      />
    </svg>
  );
};

export default School;
