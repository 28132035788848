import React from 'react';
import { Spinner } from '@nextui-org/react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center min-h-screen w-full">
      <Spinner />
    </div>
  );
};

export default Loading;
