import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Container from '../components/Container';
import ProgramModule from '../components/programmodule/ProgramModule';
import { Button } from '@nextui-org/react';
import { ReactComponent as EyeIcon } from '../components/common/icons/EyeIcon.svg';
import { ReactComponent as DownloadIcon } from '../components/common/icons/Download.svg';
import { ReactComponent as ArrowLeftIcon } from '../components/common/icons/ArrowLeft.svg';
import { useQuery } from '@apollo/client';
import { Author } from '../providers/ProfileProvider';
import { KgmProgramQuery } from '../types';
import { GET_KGM_PROGRAM } from '../graphql/kgm-program';
import { openUrlInNewTab } from '../utils';
import ProgressBanner from '../components/progess-banner/ProgressBanner';
import { useNavigate, useParams } from 'react-router-dom';
import InviteStudent from '../components/invite-student/InviteStudent';
import StudentsListing from '../components/students-listing';
import Loading from '../components/common/loading/Loading';

const ProgramDetails = () => {
  const { programId } = useParams();
  const programLabel = (programId as string).toUpperCase();
  const navigate = useNavigate();
  const { loading, data, error } = useQuery<KgmProgramQuery>(GET_KGM_PROGRAM, {
    variables: { programLabel },
  });

  if (!programLabel) {
    return (
      <div>
        No matching program information found, revist this page after sometime!
      </div>
    );
  }

  if (loading) return <Loading />;

  const programmodule = (data as KgmProgramQuery)?.programmodule;

  const downloadCurriculum = () => {
    openUrlInNewTab(programmodule.curriculum_url);
  };

  const viewStudentLessons = () => {
    openUrlInNewTab(programmodule.url);
  };

  const viewAllPrograms = () => {
    navigate('/');
  };

  // TODO: Remove the Author fragment below when Students are allowed to access multiple programs
  return (
    <Container>
      <Author>
        <div className="pt-4">
          <Button
            role="button"
            color="default"
            variant="light"
            startContent={
              <div
                className="flex items-center justify-center rounded-full cursor-pointer"
                data-testid="programs-btn-icon"
              >
                <ArrowLeftIcon
                  width={24}
                  height={24}
                  role="img"
                  aria-label=""
                />
              </div>
            }
            onPress={viewAllPrograms}
            className="font-dmsans-medium text-md px-0 data-[focus-visible=true]:outline-none data-[hover=true]:bg-transparent focus:outline-0"
            size="md"
          >
            Back to all Programs
          </Button>
        </div>
      </Author>
      {error ? (
        <div>The program you're looking for is not available.</div>
      ) : (
        <>
          <div className="w-full py-4 border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex-col items-center justify-between flex 2md:flex-row">
              <div className="w-full">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <p className="font-dmsans-bold text-3xl font-bold text-foreground pr-2">
                    Program {programLabel}:
                  </p>
                  <p
                    className="text-2xl text-foreground"
                    data-testid="program-name"
                  >
                    {programmodule?.name}
                  </p>
                </div>
                <p className="pb-4 2md:pb-0 text-base text-subtitle pt-3.5">
                  Please take a look at the {programLabel} modules listed below
                </p>
              </div>
              <Author>
                <InviteStudent program_label={programLabel} variant="solid" />
              </Author>
            </div>
          </div>
          <ProgressBanner progress={programmodule.progress} />

          <div className="grid grid-cols-1 gap-10 md:grid-cols-1 2md:grid-cols-2 xl:grid-cols-3 py-10">
            {programmodule?.modules?.map((kgmModule, i) => {
              return <ProgramModule key={i} summary={kgmModule} />;
            })}
          </div>
          <Author>
            <>
              <div>
                <div className="w-full p-5 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="gap-4 flex-col items-center justify-between flex md:flex-row md:gap-0">
                    <h2 className="text-xl font-dmsans-bold text-foreground">
                      Want to see how Kids Get Money maps to the Australian
                      National Curriculum?
                    </h2>
                    <Button
                      data-testid="curriculum-download"
                      color="secondary"
                      variant="bordered"
                      startContent={
                        <DownloadIcon
                          role="img"
                          aria-label=""
                          className="fill-secondary"
                        />
                      }
                      onPress={downloadCurriculum}
                      className="font-dmsans-medium text-lg md:mr-2 w-full md:min-w-[313px] md:max-w-[313px] active:bg-secondary/10 hover:bg-secondary/10 hover:text-secondary active:text-secondary focus:outline-0"
                      size="lg"
                    >
                      Download {programLabel} Curriculum
                    </Button>
                  </div>
                </div>
              </div>
              <div className="py-10">
                <div className="w-full p-5 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="gap-4 flex-col items-center justify-between flex md:flex-row md:gap-0">
                    <h2 className="text-xl font-dmsans-bold  text-foreground">
                      Would you like to see what your students will see in their
                      lessons?
                    </h2>

                    <Button
                      data-testid="course-view"
                      color="secondary"
                      variant="bordered"
                      startContent={
                        <EyeIcon
                          role="img"
                          aria-label=""
                          className="fill-secondary stroke-white"
                        />
                      }
                      onPress={viewStudentLessons}
                      className="font-dmsans-medium text-lg mr-2 w-full md:min-w-[313px] md:max-w-[313px] active:bg-secondary/10 hover:bg-secondary/10 hover:text-secondary active:text-secondary focus:outline-0"
                      size="lg"
                    >
                      View Student Lessons
                    </Button>
                  </div>
                </div>
              </div>
            </>
          </Author>
          <Author>
            <div className="pb-16">
              <StudentsListing />
            </div>
          </Author>
        </>
      )}
    </Container>
  );
};

export default withAuthenticationRequired(ProgramDetails);
