import React from 'react';
import AuthenticationProvider from './providers/AuthenticationProvider';
import Layout from './Layout';
import ApolloAuthProvider from './providers/ApolloAuthProvider';
import ProfileProvider from './providers/ProfileProvider';
import { NextUIProvider } from '@nextui-org/system';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ErrorBoundary } from './components/common/ErrorBoundary';
import AppLogout from './components/AppLogout';

const App = () => {
  return (
    <NextUIProvider>
      <NextThemesProvider
        attribute="class"
        themes={['author', 'learner']}
        defaultTheme="author"
      >
        <ErrorBoundary>
          <AuthenticationProvider>
            <AppLogout>
              <ApolloAuthProvider>
                <ProfileProvider>
                  <Layout />
                </ProfileProvider>
              </ApolloAuthProvider>
            </AppLogout>
          </AuthenticationProvider>
        </ErrorBoundary>
      </NextThemesProvider>
    </NextUIProvider>
  );
};

export default App;
