import React from 'react';
import { Link } from '@nextui-org/react';

type State = {
  error?: Error;
};

export type ErrorLike = {
  message: string;
  name?: string;
};

export const isErrorLike = (value: unknown): value is ErrorLike =>
  typeof value === 'object' &&
  value !== null &&
  ('stack' in value || 'message' in value) &&
  !('__typename' in value);

export const asError = (value: unknown): Error => {
  if (value instanceof Error) {
    return value;
  }
  if (isErrorLike(value)) {
    return Object.assign(new Error(value.message), value);
  }
  return new Error(String(value));
};

export class ErrorBoundary extends React.PureComponent<
  React.PropsWithChildren,
  State
> {
  public state: State = {};

  public static getDerivedStateFromError(error: never): Pick<State, 'error'> {
    return { error: asError(error) };
  }

  public render(): React.ReactNode | null {
    if (this.state.error !== undefined) {
      return (
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
              <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-secondary-600">
                Ugh
              </h1>
              <p
                className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white"
                data-testid="error-title"
              >
                Something went wrong
              </p>
              <p className="mb-4 text-lg font-light text-gray-500 dark:text-subtitle">
                Please try again after sometime.
              </p>
              <Link
                href="/"
                className="inline-flex text-white bg-secondary-600 hover:bg-secondary-500 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
              >
                Back to Homepage
              </Link>
            </div>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}
