import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Container from '../components/Container';
import ProgramCard from '../components/program/ProgramCard';
import { ReactComponent as ArrowRightIcon } from '../components/common/icons/ArrowRight3d.svg';
import { GET_KGM_PROGRAMS } from '../graphql/kgm-program';
import { KgmProgramsQuery, Program } from '../types';
import { useQuery } from '@apollo/client';
import Loading from '../components/common/loading/Loading';
import { Author, Learner } from '../providers/ProfileProvider';
import { Navigate } from 'react-router-dom';

const Home = () => {
  const { loading, data, error } = useQuery<KgmProgramsQuery>(GET_KGM_PROGRAMS);

  if (loading) return <Loading />;

  if (error) return null;

  const programs_by_type = Object.values(
    data?.programs?.reduce(
      (acc, program) => {
        if (acc[program.program_type]) {
          acc[program.program_type]['programs'].push(program);
        } else {
          acc[program.program_type] = {
            program_type: program.program_type,
            programs: [program],
          };
        }
        return acc;
      },
      {} as Record<string, { program_type: string; programs: Program[] }>,
    ) || {},
  ).sort((a, b) => a.program_type.localeCompare(b.program_type));

  // TODO: Remove the Learner fragment below when Students are allowed to access multiple programs
  return (
    <Container>
      <Learner>
        <Navigate to={`/program/${programs_by_type[0].programs[0].label}`} />
      </Learner>
      <div className="w-full py-4 border-b-2 border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full">
          <h5 className="font-dmsans-bold text-3xl font-bold text-foreground">
            All Programs
          </h5>

          <p className="pb-4 2md:pb-0 text-base text-subtitle pt-3.5">
            <Author>
              Please review the programs you have purchased, as listed below
            </Author>
            <Learner>
              Please review the programs you have been assigned to, as listed
              below
            </Learner>
          </p>
        </div>
      </div>
      <div className="py-10">
        {programs_by_type.map(program_group => {
          return (
            <div key={program_group.program_type}>
              <div className="flex items-center">
                <ArrowRightIcon role="img" aria-label="Program group" />
                <h5
                  className="text-xl font-dmsans-bold text-foreground"
                  data-testid="title"
                >
                  {program_group.program_type}
                </h5>
              </div>
              <div
                className="grid grid-cols-1 gap-10 md:grid-cols-1 2md:grid-cols-2 xl:grid-cols-3 pt-[18px] pb-10"
                data-testid="programs-by-type"
              >
                {program_group.programs.map(program => {
                  return <ProgramCard key={program.label} program={program} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Home);
