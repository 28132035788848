import { useAuth0 } from '@auth0/auth0-react';
import { useIdle } from '@uidotdev/usehooks';
import { FC, PropsWithChildren, useEffect } from 'react';

const timeoutInMS = 30 * 60 * 1000;

const AppLogout: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, logout } = useAuth0();
  const isIdle = useIdle(timeoutInMS);

  useEffect(() => {
    if (isIdle && isAuthenticated) {
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
  }, [isIdle, isAuthenticated, logout]);

  return children;
};

export default AppLogout;
