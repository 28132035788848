import React, { FC } from 'react';

const LessonPrefix: FC<{ title: string }> = ({ title }) => (
  <div
    className="flex items-center rounded-small justify-center w-17 text-md leading-12 pr-2"
    data-testid="lesson-prefix"
  >
    {title}.
  </div>
);
export default LessonPrefix;
