import { gql } from '@apollo/client';

export const GET_MY_PROFILE = gql`
  query UserProfile {
    user {
      first_name
      last_name
      email
      client {
        id
        name
      }
      role
    }
  }
`;
