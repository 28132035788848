import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  NavbarMenuToggle,
  NavbarMenuItem,
  NavbarMenu,
  Button,
} from '@nextui-org/react';
import SchoolIcon from '../icons/School';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as Logo } from '../icons/Logo.svg';
import { ReactComponent as ChevronDownIcon } from '../icons/ChevronDown.svg';
import { ReactComponent as LogoutIcon } from '../icons/Logout.svg';
import { School, useProfile } from '../../../providers/ProfileProvider';
import { useTheme } from 'next-themes';
import Loading from '../loading/Loading';

const Header = () => {
  const { theme } = useTheme();
  const { profile, loading } = useProfile();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const role = theme as 'author' | 'learner';
  const logoutIconClass: Record<'author' | 'learner', string> = {
    author: 'fill-white',
    learner: 'fill-secondary',
  };

  const initials = `${profile?.first_name[0] || ''}${
    profile?.last_name[0] || ''
  }`;
  const { logout } = useAuth0();

  const doLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {loading && <Loading />}

      <Navbar
        height={'78px'}
        isBordered
        className="border-b-2 sm:px-unit-11 bg-nav"
        maxWidth="full"
        onMenuOpenChange={setIsMenuOpen}
        classNames={{
          wrapper: 'max-w-[1650px] mx-auto',
        }}
      >
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className="sm:hidden text-icon"
        />
        <NavbarBrand className="flex justify-end sm:justify-start">
          <Logo
            width={118}
            height={44}
            className="text-black learner:text-white fill-icon"
            data-testid="logo"
            role="img"
            aria-label="Kids Get Money Logo"
          />
        </NavbarBrand>

        <School>
          <div className="hidden sm:flex gap-4">
            <div className="flex items-center space-x-4">
              <div className="p-1 bg-secondary-100/80 rounded">
                <SchoolIcon
                  height={24}
                  width={24}
                  className="fill-secondary"
                  role="img"
                  aria-label="School logo"
                />
              </div>

              <div className="font-dmsans-bold text-lg leading-4 text-navforeground">
                <div>{profile?.client?.name}</div>
              </div>
            </div>
          </div>
        </School>

        <NavbarContent as="div" justify="end" className="hidden sm:flex">
          <div className="flex items-center space-x-4">
            <div className="text-navforeground">
              <div className="font-dmsans-medium text-sm">
                {profile?.first_name} {profile?.last_name}
              </div>
              <div
                className="text-navforeground author:text-gray-400 capitalize leading-5 font-normal text-sm"
                data-testid="rolename"
              >
                {profile?.role?.toLowerCase()}
              </div>
            </div>
            <Avatar
              classNames={{
                base: 'transition-transform',
                name: 'leading-5 font-bold text-nav uppercase',
              }}
              color="primary"
              name={initials || 'KM'}
              size="md"
              radius="md"
              aria-hidden="true"
            />
          </div>
          <Dropdown placement="bottom-end" tabIndex={0}>
            <DropdownTrigger
              className="cursor-pointer"
              aria-label="Open Profile Actions"
            >
              <Button
                isIconOnly
                color="default"
                aria-label="Profile Actions"
                className=" bg-transparent focus:outline-0"
              >
                <ChevronDownIcon
                  className="stroke-icon"
                  role="img"
                  aria-label="icon"
                />
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Profile Actions"
              variant="flat"
              role="listbox"
            >
              <DropdownItem
                key="profile"
                className="h-14 gap-2"
                textValue="user-email"
                aria-label="User Email"
              >
                <p className="text-xs font-semibold">Signed in as</p>
                <p className="font-semibold">{profile?.email}</p>
              </DropdownItem>
              <DropdownItem
                key="logout"
                color="danger"
                onPress={doLogout}
                textValue="user-logout"
                aria-label="Logout"
              >
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarContent>
        <NavbarMenu className="bg-nav">
          <School>
            <NavbarMenuItem>
              <div className="text-navforeground flex items-center space-x-4">
                <div className="flex items-center space-x-4">
                  <div className="p-1 bg-secondary-100/80 rounded">
                    <SchoolIcon
                      height={33}
                      width={33}
                      className="fill-secondary"
                      role="img"
                      aria-label="School logo"
                    />
                  </div>

                  <div className="font-dmsans-bold text-lg leading-4 text-navforeground">
                    <div>{profile?.client?.name}</div>
                  </div>
                </div>
              </div>
            </NavbarMenuItem>
          </School>
          <NavbarMenuItem>
            <div className="text-navforeground flex items-center space-x-4">
              <Avatar
                classNames={{
                  base: 'transition-transform',
                  name: 'leading-5 font-bold text-nav uppercase',
                }}
                color="primary"
                name={initials || 'KM'}
                size="md"
                radius="md"
              />
              <div className="text-navforeground">
                <div className="font-dmsans-medium text-sm">
                  {profile?.first_name} {profile?.last_name}
                </div>
                <div
                  className="text-navforeground author:text-gray-400 capitalize leading-5 font-normal text-sm"
                  data-testid="rolename"
                >
                  {profile?.role?.toLowerCase()}
                </div>
              </div>
            </div>
          </NavbarMenuItem>
          <NavbarMenuItem onClick={doLogout}>
            <div className="text-navforeground flex items-center space-x-4">
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-primary rounded">
                  <LogoutIcon
                    height={40}
                    width={40}
                    className={logoutIconClass[role]}
                  />
                </div>
                <div
                  className="text-navforeground author:text-gray-400 capitalize leading-5 font-normal text-sm"
                  data-testid="rolename"
                >
                  Logout
                </div>
              </div>
            </div>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <div className="text-navforeground">
              <p className="text-xs font-semibold">Signed in as</p>
              <p className="text-sm font-semibold">{profile?.email}</p>
            </div>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
    </>
  );
};

export default Header;
