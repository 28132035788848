import { gql } from '@apollo/client';

export const GET_LEARNER_PROGRESS = gql`
  query LearnerProgress(
    $pageNum: Int
    $limit: Int!
    $program: String!
    $invited_by_me: Boolean
  ) {
    learners(
      pageNum: $pageNum
      limit: $limit
      program: $program
      invited_by_me: $invited_by_me
    ) {
      nextPageNum
      total
      learners {
        name
        email
        id
        progress {
          module
          lesson_progress {
            score
            lesson_no
            status
          }
        }
        inviter {
          name
          email
        }
      }
    }
  }
`;
