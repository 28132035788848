import React from 'react';
import EmptyTableIcon from '../common/icons/empty_table.svg';
import { Image } from '@nextui-org/react';
import InviteStudent from '../invite-student/InviteStudent';

export default (props: { programLabel: string; error: string | undefined }) => (
  <div
    className="flex flex-col flex-nowrap justify-center items-center content-center gap-4 scroll-smooth"
    aria-label="Empty table"
  >
    <Image className="flex flex-col" src={EmptyTableIcon} alt="Empty" />{' '}
    {props.error && (
      <>
        <p className="flex font-bold leading-6 text-left h-4 text-black break-words flex-col">
          Failed to fetch students in {props.programLabel} program. Please try
          to refresh the page.
        </p>
      </>
    )}
    {!props.error && (
      <>
        <p className="flex font-bold leading-6 text-left h-4 text-black break-words flex-col">
          {props.programLabel} Program looks kinda empty! Invite students to
          begin.
        </p>
        <span className="flex flex-col" data-testid="invite-student-btn">
          <InviteStudent program_label={props.programLabel} variant="light" />
        </span>
      </>
    )}
  </div>
);
