import { RouteObject, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import ProgramDetails from './pages/ProgramDetails';
import App from './App';
import ErrorPage from './pages/Error';

export const routeConfig: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/program/:programId',
        element: <ProgramDetails />,
      },
    ],
  },
];

export default createBrowserRouter(routeConfig);
