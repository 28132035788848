import { gql } from '@apollo/client';

export const GET_KGM_PROGRAM = gql`
  query ProgramDetails($programLabel: String!) {
    programmodule(program: $programLabel) {
      name
      curriculum_url
      url
      progress
      modules {
        name
        logo_url
        status
        progress
        lessons {
          id
          title
          completed
          url
          activity_url
          plan_url
          index
        }
      }
    }
  }
`;

export const GET_KGM_PROGRAMS = gql`
  query KgmPrograms {
    programs {
      label
      description
      program_type
      title
      total_lessons
      total_modules
    }
  }
`;
