import React, { FC } from 'react';
import { Learner, useProfile } from '../../providers/ProfileProvider';
import { ReactComponent as Bg } from './bg.svg';

const ProgressBanner: FC<{ progress: string }> = ({ progress }) => {
  const { profile } = useProfile();
  const madeProgress = parseInt(progress || '0') > 0;

  return (
    <Learner>
      <div className="pt-10">
        <div
          className="bg-white rounded-xl shadow-md md:h-56"
          data-testid="learner-banner"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-0">
            <div className="px-10 py-20 sm:p-[40px]">
              <div>
                <span className="text-foreground text-2xl font-bold font-dmsans-medium leading-10 pr-1">
                  Hello
                </span>
                <span className="text-secondary text-2xl font-bold leading-10">
                  {profile?.first_name}
                </span>
              </div>
              <div className="w-full md:w-[401px] text-foreground md:text-xl font-medium md:leading-loose pt-[18px]">
                {madeProgress ? (
                  <>
                    You have successfully completed {progress} of your
                    lessons... Keep it up!
                  </>
                ) : (
                  <>
                    You haven&apos;t started your lessons yet... click on the
                    lessons below to start!
                  </>
                )}
              </div>
            </div>
            <div className="hidden xl:col-span-2 xl:flex justify-end">
              <Bg
                className=" scale-100 xl:scale-100 translate-x-0 h-[223px] w-full "
                role="img"
                aria-label="Background image for student progress"
              />
            </div>
          </div>
        </div>
      </div>
    </Learner>
  );
};

export default ProgressBanner;
