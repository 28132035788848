import { gql } from '@apollo/client';

export const INVITE_STUDENT = gql`
  mutation InviteStudent($inviteLearnersInput: InviteLearnersInput!) {
    invite_learners(inviteLearnersInput: $inviteLearnersInput) {
      errors {
        email
        reason
      }
    }
  }
`;
