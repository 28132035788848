import React, { FC, ReactNode } from 'react';

const Container: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="px-unit-7 sm:px-unit-17 md:px-unit-20 2md:px-unit-17 ">
      {children}
    </div>
  );
};

export default Container;
