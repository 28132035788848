export const TableConstants = {
  STUDENT: {
    name: 'Student',
    uid: 'student',
  },
  TEACHER: { name: 'Teacher', uid: 'teacher' },
  MODULE1: {
    name: `Module 1 Completion`,
    uid: 'module1_completion',
  },
  MODULE2: {
    name: `Module 2 Completion`,
    uid: 'module2_completion',
  },
  MODULE3: {
    name: `Module 3 Completion`,
    uid: 'module3_completion',
  },
};

export const allStudentsTableColumns = [
  { ...TableConstants.STUDENT },
  { ...TableConstants.TEACHER },
  { ...TableConstants.MODULE1 },
  { ...TableConstants.MODULE2 },
  { ...TableConstants.MODULE3 },
];

export const myStudentsTableColumns = [
  { ...TableConstants.STUDENT },
  { ...TableConstants.MODULE1 },
  { ...TableConstants.MODULE2 },
  { ...TableConstants.MODULE3 },
];

export enum StudentListTableFilter {
  ALL_STUDENTS = 'allstudents',
  MY_STUDENTS = 'mystudents',
}
