import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTheme } from 'next-themes';
import { GET_MY_PROFILE } from '../graphql/user';
import { ProfileQuery, Role, UserProfile } from '../types';
import { useAuth0 } from '@auth0/auth0-react';

type ProfileProviderProps = {
  profile: UserProfile;
  loading: boolean;
  error: ApolloError | undefined;
  isAuthor: boolean;
  isLearner: boolean;
};
const ProfileContext = createContext<ProfileProviderProps>({
  profile: {} as UserProfile,
  loading: false,
} as ProfileProviderProps);

const ProfileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [getProfile, { data, loading, error }] = useLazyQuery<ProfileQuery>(
    GET_MY_PROFILE,
    {},
  );
  const { theme, setTheme } = useTheme();

  const getUserProfile = useCallback(() => {
    if (isAuthenticated) {
      getProfile();
    }
  }, [getProfile, isAuthenticated]);

  useEffect(() => {
    getUserProfile();
  }, [getProfile, getUserProfile]);

  useEffect(() => {
    if (data?.user?.role) {
      if ([Role.TEACHER, Role.PARENT].includes(data?.user.role)) {
        setTheme('author');
      } else {
        setTheme('learner');
      }
    }
  }, [data?.user?.role, setTheme]);

  const value = useMemo(
    () => ({
      profile: data?.user as UserProfile,
      loading: loading || isLoading,
      error,
      isAuthor: theme === 'author',
      isLearner: theme === 'learner',
    }),
    [error, loading, data?.user, theme, isLoading],
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export default ProfileProvider;

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const Author: FC<{ children: ReactNode }> = ({ children }) => {
  const { profile, loading } = useProfile();

  if (loading) {
    return null;
  }
  if ([Role.TEACHER, Role.PARENT].includes(profile?.role)) {
    return <>{children}</>;
  }
  return null;
};

export const Learner: FC<{ children: ReactNode }> = ({ children }) => {
  const { profile, loading } = useProfile();

  if (loading) {
    return null;
  }
  if ([Role.STUDENT, Role.KID].includes(profile?.role)) {
    return <>{children}</>;
  }
  return null;
};

export const School: FC<{ children: ReactNode }> = ({ children }) => {
  const { profile, loading } = useProfile();

  if (loading) {
    return null;
  }
  if (
    [Role.STUDENT, Role.TEACHER].includes(profile?.role) &&
    profile?.client?.id
  ) {
    return <>{children}</>;
  }
  return null;
};
