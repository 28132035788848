import React from 'react';
import { ReactComponent as TickCircle } from '../common/icons/TickCircle.svg';
import { ReactComponent as MinusCircle } from '../common/icons/MinusCirlce.svg';
import { ModuleProgress } from '../../types';
import { Tooltip } from '@nextui-org/react';

const LessonProgressStatusContainer = (props: {
  progress: {
    status?: string;
    lesson_no: number;
    score?: string | null;
  };
  module: number;
}) => {
  if (props.progress.score && props.progress.status === 'Completed') {
    return (
      <>
        <Tooltip
          content={`Successfully completed Module ${props.module} with ${props.progress.score} score`}
        >
          <span className="inline-flex text-foreground text-bold p-1 justify-center">
            {props.progress.score}
          </span>
        </Tooltip>
        <span className="sr-only">
          {props.progress.score}-
          {`Successfully completed Module ${props.module} with ${props.progress.score} score`}
        </span>
      </>
    );
  }
  if (props.progress.status === 'Completed') {
    return (
      <>
        <Tooltip
          content={`Successfully completed Lesson ${props.progress.lesson_no} in Module ${props.module}`}
        >
          <span>
            <TickCircle />
          </span>
        </Tooltip>
        <span className="sr-only">
          {props.progress.status}-
          {`Successfully completed lesson ${props.progress.lesson_no} in Module ${props.module}`}
        </span>
      </>
    );
  }
  if (props.progress.status === 'NotCompleted') {
    return (
      <>
        <Tooltip
          content={`Not completed Lesson ${props.progress.lesson_no} in Module ${props.module}`}
        >
          <span
            data-testid="inprogress-lesson-status"
            className="h-7 w-7 focus:outline-0"
          ></span>
        </Tooltip>
        <span className="sr-only">
          {props.progress.status}-
          {`Not completed lesson ${props.progress.lesson_no} in Module ${props.module}`}
        </span>
      </>
    );
  }
  if (props.progress.status === undefined) {
    return (
      <>
        <Tooltip
          content={`There isn't Lesson ${props.progress.lesson_no} in Module ${props.module}`}
        >
          <span>
            <MinusCircle role="img" aria-label="" />
          </span>
        </Tooltip>
        <span className="sr-only">{`There isn't Lesson ${props.progress.lesson_no} in Module ${props.module}`}</span>
      </>
    );
  }
  return <span></span>;
};

export const LessonProgress = (props: { data: ModuleProgress }) => {
  const progress = props.data;
  return (
    <div className="flex items-start">
      {[1, 2, 3, 4, 5, 6, 7, 8].map(lesson_num => {
        const lessonProgress =
          progress.lesson_progress.length >= lesson_num
            ? progress.lesson_progress[lesson_num - 1]
            : {
                lesson_no: lesson_num,
                status: undefined,
              };
        return (
          <span
            key={lessonProgress.lesson_no}
            style={{
              borderRight: '#F3F5F5',
              borderBottom: 'none',
              borderTop: 'none',
            }}
            className="border-1 border-solid inline-flex flex-col items-center gap-1 w-8"
          >
            <div>{lessonProgress.lesson_no}</div>
            <LessonProgressStatusContainer
              progress={lessonProgress}
              module={progress.module}
            />
          </span>
        );
      })}
    </div>
  );
};
