import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Link } from '@nextui-org/react';

const ErrorPage = () => {
  const error = useRouteError() as Error;
  let code, title, message;

  if (isRouteErrorResponse(error)) {
    code = error.status;
    switch (error.status) {
      case 404:
        title = 'Something Went Wrong';
        message =
          "Sorry, we couldn't find the page you're looking for. Please go back to the homepage to explore more";
        break;
      case 401:
        title = 'Unauthorized.';
        message = 'You are not authorized to see this';
        break;

      case 503:
        title = 'Unavilable.';
        message = 'Looks like our API is down';
        break;
      default:
        title = 'Something went wrong.';
        message = error?.message || 'Please again after sometime.';
    }
  }

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-secondary-600">
            {code}
          </h1>
          <p
            className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white"
            data-testid="error-title"
          >
            {title}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-subtitle">
            {message}
          </p>
          <Link
            href="/"
            className="inline-flex text-white bg-secondary-600 hover:bg-secondary-500 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
          >
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};
export default ErrorPage;
