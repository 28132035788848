import React, { FC, useCallback, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  cn,
} from '@nextui-org/react';
import { ReactComponent as PlusIcon } from '../common/icons/PlusIcon.svg';
import { ReactComponent as CloseIcon } from '../common/icons/CloseCircle.svg';
import { ReactComponent as PlusCircleIcon } from '../common/icons/AddCircle.svg';
import {
  useForm,
  useFieldArray,
  FieldErrors,
  UseFieldArrayRemove,
  UseFormRegister,
} from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { INVITE_STUDENT } from '../../graphql/teacher';
import { toast } from 'react-toastify';
import { VALIDATIONS } from '../../constants';

type DisplayedTaskCheck = {
  email: string;
  first_name: string;
  last_name: string;
};

const EMPTY_RECORD: DisplayedTaskCheck = {
  email: '',
  first_name: '',
  last_name: '',
};

type InviteLearnerError = {
  reason: string;
  email: string;
};

type InviteLearnersResponse = {
  errors: InviteLearnerError[];
};

type UserDetails = {
  first_name: string;
  last_name: string;
  email: string;
};

type InviteLearnersInput = {
  program_label: string;
  students: UserDetails[];
};

const INPUT_CLASSES =
  'border-small group-data-[focus=true]:border-gray-400 data-[hover=true]:border-gray-400';
const INPUT_ERROR_CLASSES =
  'border-small group-data-[focus=true]:border-danger data-[hover=true]:border-danger';

const Fields: FC<{
  register: UseFormRegister<{
    students: DisplayedTaskCheck[];
  }>;
  remove: UseFieldArrayRemove;
  index: number;
  errors: FieldErrors<{
    students: DisplayedTaskCheck[];
  }>;
}> = ({ index, errors, remove, register }) => {
  const onDelete = () => {
    remove(index);
  };
  const invalid_email = !!errors.students?.[index]?.email;
  const invalid_first_name = !!errors.students?.[index]?.first_name;
  const invalid_last_name = !!errors.students?.[index]?.last_name;

  return (
    <div className="flex w-full flex-wrap md:flex-nowrap gap-4 min-h-[76px]">
      <Input
        type="email"
        label={
          <>
            Email<span aria-hidden="true">*</span>
          </>
        }
        placeholder="eg: name.lastname@gmail.com"
        {...register(`students.${index}.email`, VALIDATIONS.email)}
        color={invalid_email ? 'danger' : 'default'}
        isInvalid={invalid_email}
        errorMessage={
          <span aria-live="assertive">
            {errors.students?.[index]?.email?.message}
          </span>
        }
        variant="bordered"
        classNames={{
          inputWrapper: invalid_email ? INPUT_ERROR_CLASSES : INPUT_CLASSES,
        }}
        autoCorrect="off"
        autoComplete="off"
        aria-required="true"
      />

      <Input
        label={
          <>
            First Name<span aria-hidden="true">*</span>
          </>
        }
        placeholder="eg: Sam"
        className="md:max-w-[231px]"
        {...register(`students.${index}.first_name`, VALIDATIONS.first_name)}
        color={invalid_first_name ? 'danger' : 'default'}
        isInvalid={invalid_first_name}
        errorMessage={
          <span aria-live="assertive">
            {errors.students?.[index]?.first_name?.message}
          </span>
        }
        variant="bordered"
        classNames={{
          inputWrapper: invalid_first_name
            ? INPUT_ERROR_CLASSES
            : INPUT_CLASSES,
        }}
        autoCorrect="off"
        autoComplete="off"
        aria-required="true"
      />

      <Input
        label={
          <>
            Last Name<span aria-hidden="true">*</span>
          </>
        }
        placeholder="eg: Smith"
        className="md:max-w-[231px]"
        {...register(`students.${index}.last_name`, VALIDATIONS.last_name)}
        color={invalid_last_name ? 'danger' : 'default'}
        isInvalid={invalid_last_name}
        errorMessage={
          <span aria-live="assertive">
            {errors.students?.[index]?.last_name?.message}
          </span>
        }
        variant="bordered"
        classNames={{
          inputWrapper: invalid_last_name ? INPUT_ERROR_CLASSES : INPUT_CLASSES,
        }}
        autoCorrect="off"
        autoComplete="off"
        aria-required="true"
      />
      <Button
        isIconOnly
        color="default"
        variant="light"
        aria-label="Delete"
        onPress={onDelete}
        isDisabled={index === 0}
        className="mt-2 hidden md:flex"
      >
        <CloseIcon />
      </Button>
      <Button
        color="default"
        variant="solid"
        aria-label="Delete"
        onPress={onDelete}
        isDisabled={index === 0}
        className="mb-4 md:hidden w-full"
      >
        <CloseIcon />
        Delete
      </Button>
    </div>
  );
};

const InviteStudent: FC<{
  program_label: string;
  className?: string;
  variant: 'light' | 'solid';
}> = ({ program_label, className, variant }) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [addStudents, { loading }] = useMutation<
    { invite_learners: InviteLearnersResponse },
    { inviteLearnersInput: InviteLearnersInput }
  >(INVITE_STUDENT);
  const { register, control, handleSubmit, reset, formState } = useForm<{
    students: DisplayedTaskCheck[];
  }>({
    defaultValues: {
      students: [EMPTY_RECORD],
    },
    mode: 'all',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'students',
  });

  const onSubmit = useCallback(
    async (data: { students: DisplayedTaskCheck[] }) => {
      const res = await addStudents({
        variables: {
          inviteLearnersInput: {
            program_label,
            students: data.students,
          },
        },
      });
      if (res.data?.invite_learners?.errors?.length === 0) {
        toast.success(
          () => (
            <span role="alert">
              <h5 className="text-lg font-dmsans-bold  text-foreground">
                Email Successfully Sent
              </h5>
              <p className="text-md leading-normal text-gray-500">
                If the emails entered are valid, an invitation has been sent.
                Students who have been invited will appear in the student's
                table.
              </p>
            </span>
          ),
          {
            className: 'bg-[#00CD9333] rounded',
            delay: 0,
          },
        );
        reset({
          students: [EMPTY_RECORD],
        });
        setTimeout(() => {
          onClose();
        });
      } else {
        setApiErrors(
          res.data?.invite_learners?.errors?.map(e => e.reason) || [],
        );
      }
    },
    [addStudents, onClose, program_label, reset],
  );

  const addNewStudent = useCallback(() => {
    append({ ...EMPTY_RECORD });
  }, [append]);

  const onDismiss = useCallback(() => {
    reset({
      students: [EMPTY_RECORD],
    });
    setApiErrors([]);
  }, [reset]);

  const cancel = useCallback(() => {
    onDismiss();
    setTimeout(() => {
      onClose();
    });
  }, [onClose, onDismiss]);

  const canSubmit = loading || !formState.isValid;

  return (
    <>
      <Button
        role="button"
        data-testid="invite"
        color="secondary"
        variant={variant}
        startContent={
          <PlusIcon
            className={
              variant === 'light'
                ? 'fill-secondary group-hover/invite-students:fill-focus'
                : 'bg-secondary group-hover/invite-students:bg-focus fill-white'
            }
            role="img"
            aria-label=""
          />
        }
        className={cn(
          'w-full font-dmsans-medium text-lg font-medium 2md:max-w-fit active:bg-focus hover:bg-focus focus:outline-0 group/invite-students',
          className,
        )}
        size="lg"
        onPress={onOpen}
      >
        Invite New Students
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        size="5xl"
        classNames={{
          closeButton: 'mt-4 [&>*]:w-6 [&>*]:h-6 mr-5',
        }}
        scrollBehavior="outside"
        placement="center"
        onClose={onDismiss}
      >
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader className="flex flex-col gap-1 py-6">
              <h5
                className="text-xl font-dmsans-bold text-foreground"
                data-testid="title"
              >
                Invite New Students to {program_label} Program
              </h5>
              <p className="text-md leading-normal text-gray-500 font-normal">
                Fill out the fields below with the student's email address,
                first name, and last name (for school subscriptions the email
                must be the student school email):
              </p>
            </ModalHeader>
            <ModalBody className="min-h-[300px]">
              <div>
                {apiErrors.map((err, index) => {
                  return (
                    <div
                      className="p-4 mb-4 text-md leading-6 text-danger rounded-lg bg-red-50 font-dmsans-medium"
                      role="alert"
                      key={index}
                    >
                      {err}. Please contact support@kidsget.money to resolve.
                    </div>
                  );
                })}
              </div>

              {fields.map((item, index) => {
                return (
                  <div key={item.id}>
                    <Fields
                      errors={formState.errors}
                      index={index}
                      register={register}
                      remove={remove}
                    />
                  </div>
                );
              })}
              <div></div>
              <div>
                <Button
                  color="success"
                  variant="light"
                  startContent={
                    <PlusCircleIcon
                      role="img"
                      aria-label="Add Another Student"
                    />
                  }
                  className="w-full sm:w-fit p-0 data-[hover=true]:bg-transparent"
                  onPress={addNewStudent}
                  data-testid="add-more-btn"
                >
                  Add Another Student
                </Button>
              </div>
            </ModalBody>
            <ModalFooter className="py-6">
              <Button color="default" variant="bordered" onPress={cancel}>
                Close
              </Button>
              <Button
                color="secondary"
                className="font-dmsans-medium text-lg font-medium md:max-w-fit active:bg-focus hover:bg-focus focus:outline-0"
                isDisabled={canSubmit}
                type="submit"
              >
                Send
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteStudent;
