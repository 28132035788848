import React, { FC, useCallback } from 'react';
import { Author, Learner, useProfile } from '../../providers/ProfileProvider';
import { ReactComponent as BookIcon } from '../common/icons/Book.svg';
import { ReactComponent as ActivityIcon } from '../common/icons/Activity.svg';
import { ReactComponent as CheckIcon } from '../common/icons/Check.svg';
import { ReactComponent as ArrowIcon } from '../common/icons/ArrowSquareRight.svg';
import { Lesson } from '../../types';
import { openUrlInNewTab } from '../../utils';
import { Button } from '@nextui-org/react';

const LessonActionItems: FC<{ lesson: Lesson }> = ({ lesson }) => {
  const { isLearner, isAuthor } = useProfile();
  const has_activity = isLearner ? lesson.activity_url.length > 0 : false;

  const openActivity = useCallback(() => {
    if (lesson.activity_url) {
      openUrlInNewTab(lesson.activity_url);
    }
  }, [lesson.activity_url]);

  const openLesson = useCallback(() => {
    openUrlInNewTab(lesson.url);
  }, [lesson.url]);

  const onLessonClick = useCallback(() => {
    if (isAuthor) {
      openUrlInNewTab(lesson.plan_url);
    }
  }, [isAuthor, lesson.plan_url]);

  return (
    <div className="flex items-center gap-1">
      <Author>
        <Button
          isIconOnly
          color="default"
          aria-label="Lesson overview"
          className="bg-white group-hover/author-item:bg-secondary focus:outline-0"
          onPress={onLessonClick}
          data-testid="author-lesson-icon"
          tabIndex={0}
        >
          <ArrowIcon
            width={15.87}
            height={13.8}
            className="stroke-foreground fill-white group-hover/author-item:fill-secondary group-hover/author-item:stroke-white"
            role="img"
            aria-label="view lesson overview"
            aria-hidden="true"
          />
        </Button>
      </Author>
      <Learner>
        <div className="flex">
          <div className="group/book">
            {lesson.completed ? (
              <Button
                isIconOnly
                color="default"
                aria-label="Lesson overview"
                className="bg-success mr-9 group-hover/book:bg-secondary focus:outline-0"
                onPress={openLesson}
                data-testid="learner-tick"
                tabIndex={0}
              >
                <CheckIcon
                  width={15.87}
                  height={13.8}
                  className="fill-foreground group-hover/book:fill-white"
                  role="img"
                  aria-label="Lesson Completed"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button
                isIconOnly
                color="default"
                aria-label="Lesson overview"
                className="bg-white mr-9 group-hover/book:bg-secondary focus:outline-0"
                onPress={openLesson}
                data-testid="learner-book"
                tabIndex={0}
              >
                <BookIcon
                  width={20.87}
                  height={20.8}
                  className="fill-foreground group-hover/book:fill-white"
                  role="img"
                  aria-label="View Lesson"
                  aria-hidden="true"
                />
              </Button>
            )}
          </div>
          <div className="group/activity">
            <Button
              isIconOnly
              color="default"
              aria-label="lesson activity"
              className={`bg-white focus:outline-0 ${
                has_activity
                  ? 'bg-white cursor-pointer group-hover/activity:bg-secondary'
                  : 'pointer-events-none bg-white/40'
              }`}
              onPress={openActivity}
              data-testid="learner-activty-icon"
              tabIndex={0}
            >
              <ActivityIcon
                width={20.87}
                height={20.87}
                className={`fill-foreground stroke-foreground ${
                  has_activity
                    ? 'group-hover/activity:fill-white group-hover/activity:stroke-white '
                    : 'stroke-foreground/80'
                }`}
                role="img"
                aria-label="View Lesson Activity"
                aria-hidden="true"
              />
            </Button>
          </div>
        </div>
      </Learner>
    </div>
  );
};

export default LessonActionItems;
